export const basicPagePath = (slug: string) => `/${slug}`

export const moviePath = (slug: string) => `/movies/${slug}`

export const showPath = (slug: string) => `/shows/${slug}`

export const watchPath = (slug: string) => `/watch/${slug}`

export const pressReleasePath = (slug: string) => `/press-releases/${slug}`

export const blogPath = (slug: string) => `/blog/${slug}`
