import React from "react"
import Helmet from "react-helmet"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { useI18n, LocaleLink as Link } from "../contexts/i18n"
import Layout from "../layout"
import SEO from "../components/SEO"
import List from "../components/List"
import Textblock from "../components/Textblock"
import { moviePath, showPath } from "../../routes"

type Content =
  | Pick<Contentful.Movie, "__typename" | "title" | "posterImageVertical" | "slug">
  | Pick<Contentful.Show, "__typename" | "title" | "posterImageVertical" | "slug">

interface DataType {
  flicks: Contentful.List<Content>
}

function IndexPage({ data }: PageProps<DataType>) {
  const { translate: t, locale } = useI18n(`homepage`)

  return (
    <Layout>
      <Helmet>
        <body className="home-page" />
      </Helmet>
      <SEO title="Home - GCFlix" />

      <div className="homepage-hero">
        <h1 className="homepage-hero-cta">{t`Innovative theatrical and episodic series`}</h1>
        <p className="homepage-hero-sub-cta">{t`Enjoy all our content for free!`}</p>
      </div>

      <List className="upcoming-movies">
        {data.flicks.items.map((flick) => (
          <Promotion key={flick.slug} {...flick} />
        ))}
      </List>

      <div className="homepage-info-section">
        {locale?.name === "en" && (
          <Textblock>
            <p>
              Welcome to our indie world. This mini platform-in-progress is here to offer you independent content for
              free. No strings attached!
            </p>
            <p>
              We want you to be part of our filmmaking journey, in which little by little we will be adding titles to
              expand our library and grow together. Without pretensions, this is a site to have fun, support indie
              filmmakers, and share creative ideas.{" "}
            </p>
            <p>All we want from you is to subscribe, watch, and give us your feedback. </p>
            <p>In the meantime, enjoy our content!</p>
          </Textblock>
        )}
        {locale?.name === "es" && (
          <Textblock>
            <p>
              Bienvenido(a) a nuestro mundo independiente. A través de esta mini plataforma en construcción, te
              ofrecemos contenido independiente totalmente GRATIS. ¡Sin condiciones!
            </p>
            <p>
              Queremos que seas parte de nuestro camino fílmico, en el que poco a poco, estaremos agregando títulos para
              expander nuestra libreria y crecer juntos. Sin pretensiones, este es un espacio para entretenerte, apoyar
              a cineastas independientes, y compartir ideas creativas.
            </p>
            <p>Lo único que te pedimos es que te subscribas, disfrutes del contenido y nos des tu feedback.</p>
          </Textblock>
        )}
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    flicks: contentfulList(title: { eq: "Homepage Movies" }) {
      items {
        __typename
        ... on ContentfulMovie {
          title
          posterImageVertical {
            gatsbyImage: gatsbyImageData(width: 200)
          }
          slug
        }
        ... on ContentfulShow {
          title
          posterImageVertical {
            gatsbyImage: gatsbyImageData(width: 200)
          }
          slug
        }
      }
    }
  }
`

const Promotion = ({ title, posterImageVertical, slug, __typename }: Content) => (
  <Link
    to={__typename === `ContentfulMovie` ? moviePath(slug) : showPath(slug)}
    title={title}
    className="movie-card-link"
  >
    <div className="movie-card">
      <GatsbyImage image={posterImageVertical.gatsbyImage} alt={title} />
      <h3 className="movie-title">{title}</h3>
    </div>
  </Link>
)
