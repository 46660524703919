import React from "react"

interface ListProps extends React.ComponentProps<"ul"> {
  children: React.ReactNode
}

function List({ children, ...listProps }: ListProps) {
  return (
    <ul {...listProps}>
      {React.Children.map(children, (child, i) => {
        const key = typeof child !== "object" ? i : !!child && "key" in child ? child.key : i
        return <li key={key}>{child}</li>
      })}
    </ul>
  )
}

export default List
